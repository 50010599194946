import './scss/index.css';

// @ts-ignore
import Search from "./assets/icon/search-lg.png";

interface Props {
    name: string;
    icon?: string;
    ph?: string;
    ws?: number;
    type?: string;
    onChange: Function;
}

// eslint-disable-next-line react/prop-types
const Empty: React.FC<Props> = ({ws = 50, type = "text", ph = "", name, icon, onChange}) => {


    let iconSrc;
    switch (icon) {
        case 'search':
            iconSrc = Search; // Обновите это вашей ссылкой на изображение
            break;
        case 'calendar':
            iconSrc = false; // Обновите это вашей ссылкой на изображение
            break;
        default:
            iconSrc = false; // Можете добавить ссылку на изображение по умолчанию, если хотите
    }

    return (
        <div className="input input-def">
            <div className="input__label">
                <p className={'text text-s14'}>
                    {name}
                </p>
            </div>
            <div className={"input__box " + (iconSrc && 'active')} style={{width: ws === -1 ? ('100%') : ws + "rem"}}>
                {
                    iconSrc && (
                        <div className="input__box-icon" style={{backgroundImage: "url('" + iconSrc + "')"}}></div>
                    )
                }
                <input onChange={(e) => {
                    if (onChange) {
                        onChange(e);
                    }

                }} type={type} placeholder={ph} className={'input__box-in  text text-s14'}/>
            </div>
        </div>
    );

}

export default Empty;