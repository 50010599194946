
import {persistStore, persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {createWhitelistFilter} from 'redux-persist-transform-filter';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

// Reducers
import StateReducer from './reducers/StateReducer';

const saveSubsetWhitelistFilter = createWhitelistFilter('', []);


const persistConfig = {
    key: 'primary',
    storage: storage,
    transform: [saveSubsetWhitelistFilter],
    blacklist: [],
    serializableCheck: false
};

const rootReducer = persistCombineReducers(persistConfig, {
    state: StateReducer,
});


export const store = configureStore({
    reducer: {rootReducer},
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false
        }),
    ],
    devTools: false
});
export const persistor = persistStore(store);
